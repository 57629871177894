@charset "utf-8";

// Import only what you need from Bulma


@import url('https://fonts.googleapis.com/icon?family=Material+Icons');
@import "../style/font-awesome/css/all.css";
@import 'react-toastify/dist/ReactToastify.css';
@import 'react-whatsapp-widget/dist/index.css';
@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700');
@import url('https://fonts.googleapis.com/css2?family=Nunito:wght@800;900');

/* @import '../style/theme/app.css';
@import '../style/theme/core.css'; */
//colores de la paleta

$azul: #9591f2;

$variante: #7466f1;

$secundary: #99879d;

$success: #2abf6b;

$warning: #f2a25c;

$rojo: #d9185f;

$link: #211266;

// Set your brand colors
$black: #000;
$primary: $azul;

$text-primary: $black;
$text-secundary: $secundary;

$white: #ffffff;

// Update Bulma's global variables
$family-sans-serif:'Montserrat',
sans-serif;

$openSans: "Open Sans",
"Helvetica Neue",
"Segoe UI",
sans-serif;

$nunito: $family-sans-serif;

$logoFont: "Nunito",
sans-serif;

//typography

$size-1:5rem;

// Update some of Bulma's component variables
$body-background-color: #fff;
$body-color:#000;

//navbar
$navbar-background-color: transparent;
$navbar-item-active-color: $azul;
$navbar-box-shadow-size: 0px 0.5px 3px 0px;
$navbar-box-shadow-color:$black;

//sections
$section-padding: 0.08rem 0.10rem;

//Box 
$box-shadow: 0 10px 20px rgba(0, 0, 0, 0.19),
0 6px 6px rgba(0, 0, 0, 0.23);


//card
$card-radius: 26px;
$card-shadow: 0 10px 20px rgba(0, 0, 0, 0.19),
0 6px 6px rgba(0, 0, 0, 0.23);

//inputs
$input-color: $black;
$input-border-color: $primary !important;
$input-arrow: $black;
$input-icon-color:$primary;
$input-icon-active-color:$secundary;

//titles
$title-family: $nunito;

//pricing

$pricing-plan-border: 0px;
$pricing-plan-border-radius: 26px;
$pricing-plan-active-shadow: 0 10px 20px rgba(0, 0, 0, 0.19),
0 6px 6px rgba(0, 0, 0, 0.23);
$pricing-plan-active-transform: scale(1, 1.02);
$pricing-header-background: transparent;
$pricing-item-background: transparent;
$pricing-item-border: 0.1rem solid white;
$pricing-item-color: $black;
$pricing-price-background: transparent;


.pricing-table {
    .pricing-plan {
        margin: 0.01rem !important;

        .plan-price {
            color: black;
            margin: 0.01rem !important;
            font-size: 1rem !important;

            .plan-price-amount {
                color: black;
                font-size:3.5rem !important;
                font-weight: 600 !important;
                .plan-price-currency {
                    color: black;
                    font-size: 1.5rem !important;
                    font-weight: 400  !important;
                }
                .plan-price-custom{
                    margin-right: 10px;
                    vertical-align: super;
                    font-size: 1.5rem !important;
                    font-weight: 400  !important;
                }
            }
        }
    }

    .plan-items {
        background: transparent;
        border-bottom: 0px !important;
        // padding: 0.5em;
        align-content: center;
        text-align: center;
        font-size: 0.9em;
        transition: width .2s;

        .plan-item {
            background: transparent;
            border-bottom: 0px !important;
            margin: 0rem;
            color: black !important;
        }
    }
}

.pricing-plan-activable {
    background-color: #5243C2 !important;
    border-color: transparent !important;
    margin: 0rem;

    .plan-price {
        color: white !important;
        margin: 0.01rem !important;

        .plan-price-amount {
            color: white !important;

            .plan-price-currency {
                color: white !important;
            }
        }
    }


    .plan-header {
        color: white !important;
    }

    .plan-description {
        color: white !important;
    }

    .plan-items {
        background: #5243C2;
        border-bottom: 0px !important;
        // padding: 0.5em;
        align-content: center;
        text-align: center;
        font-size: 0.9em;

        .plan-item {
            background: #5243C2;
            border-bottom: 0px !important;
            margin: 0rem;
            color: white !important;
        }
    }
}

//footer

$footer-background-color:$primary;
$footer-color:$black;
$footer-padding:1rem;

@import "../../node_modules/bulma/sass/base/_all.sass";
@import "../../node_modules/bulma/sass/components/_all.sass";
@import "../../node_modules/bulma/sass/elements/_all.sass";
@import "../../node_modules/bulma/sass/form/_all.sass";
@import "../../node_modules/bulma/sass/grid/_all.sass";

@import "../../node_modules/bulma/sass/helpers/_all.sass";
@import "../../node_modules/bulma/sass/layout/_all.sass";
@import "../../node_modules/bulma/sass/utilities/_all.sass";

@import "../../node_modules/bulma-extensions/bulma-pricingtable/src/sass/index.sass";

@import "../../node_modules/bulma-extensions/bulma-accordion/src/sass/index.sass";

nav {
    font-family: $nunito;
    font-weight: 600;
}

h1,
h2,
h3,
h4,
h5,
h6 {
    font-family: $nunito;
    font-weight: 900;
}

.is-link-reverse {
    color: #211266;
    background-color: white;
}

.is-link-reverse:hover {
    color: $white;
    background-color: #211266 !important;
    border-color: #211266 !important;
}

.is-link:hover {
    color: #211266 !important;
    background-color: $white !important;
    border-color: $white !important;
}

.navbar-item {
    .is-active:hover{
        color: #211266 !important;
        background-color: $white !important;
        border-color: $white !important;
    }
}

.nunito {
    font-family: $logoFont !important;
}

.navbar-end {
    .is-active {
        background-color: #45319c !important;
    }
}

.is-item-hoverable:hover {
    background-color: #5a48a7 !important;
}

.is-white-bg-resalted {
    background-color: #3cbc8d !important;
}

.text-secundary {
    color: $secundary !important;
}

.modulos {
    padding-top: 20px;
}

.primary-gradient {
    background: rgb(147, 120, 255);
    background: linear-gradient(90deg,
        rgba(147, 120, 255, 1) 100%,
        rgba(47, 128, 237, 0.27494747899159666) 100%);
}

.startNow {
    padding: 50px 50px;
    background-image: url(../images/subscribe-bg.svg);
    background-position: center;
    background-repeat: no-repeat;
}

.previews-images {
    width: 380px;
    height: 380px;
}

.is-fab {
    position: fixed;
    bottom: 1%;
    right: 1%;
    z-index: 10000;

    .whatsapp {
        position: relative;
        bottom: 50px;
        left: 35px;
    }

    .facebook {
        position: relative;
        bottom: 70px;
        right: 48px;
        box-shadow: 0 10px 20px rgba(0, 0, 0, 0.19), 0 6px 6px rgba(0, 0, 0, 0.23);
        border-radius: 33px;
        width: 48px;
    }

}


//whatapp styles mensagger

._1bpcM {
    align-items: center;
    align-self: flex-end;
    background-color: #4fce5d;
    border-radius: 50%;
    box-shadow: 0px 0px 0px 0px !important;
    color: $white;
    cursor: pointer;
    display: flex;
    font-size: 3em;
    height: 64px;
    justify-content: center;
    margin-bottom: 20px;
    margin-right: 20px;
    margin-left: 20px;
    outline: transparent;
    -webkit-user-select: none;
    user-select: none;
    width: 64px;
    -webkit-box-align: center;
    -webkit-box-pack: center;
    position: absolute;
    bottom: 15px;
    right: 30px;
}




.has-content-centered {
    display: flex;
    justify-content: center;
}

.register-user {
    margin-top: 5%;
}

sup {
    font-size: 7px;
}

.content.is-vcentered {
    display: flex;
    flex-wrap: wrap;
    align-content: center;
    align-items: center;
}

.columns.is-vcentered {
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
}

.accordions .accordion .accordion-header {

    background-color: $primary !important;
    align-items: center;
    border-radius: 4px 4px 0 0;
    color: #fff;
    display: flex;
    justify-content: space-between;
    line-height: 1.25;
    padding: 0.5em 0.75em;
    position: relative;
}


///////////////////////////////////////////////////////////////////
///////////////////////////////////////////////////////////////////
///////////////////////////////////////////////////////////////////
///////////////////////////////////////////////////////////////////



// Medium devices (tablets, 768px and up)

@media (max-width: 768px) {

    .nube_black_1 {
        position: absolute;
        top: 10%;
        width: 80px !important;
    }

    .nube_black_2 {
        position: absolute;
        top: 15% !important;
        right: 5% !important;
        width: 30px !important;
    }

    .nube_black_3 {
        opacity: .3 !important;
        position: absolute;
        bottom: 12% !important;
        left: 2% !important;
        width: 80px !important;
    }

    .nube_black_4 {
        opacity: .5 !important;
        position: absolute;
        bottom: 15% !important;
        right: 5% !important;
        width: 40px !important;
    }


    .nube_delivery {
        position: absolute;
        top: 0%;
        right: 0%;
        z-index: -2;
        width: 100px !important;
    }


    .nubefa_girl_2 {
        position: relative !important;
        bottom: 0%;
        left: 0%;
        width: 100px;
    }

    .nubefa_girl_3 {
        z-index: -2;
        width: 160px !important;
        position: absolute !important;
        top: 1% !important;
        left: 5% !important;
    }

    .nube_backgroud {
        position: relative;
    }

    .nube_backgroud:before {
        opacity: .3;
        background-image: url('../images/NUBE_MORADA.png');
    }

}


// XX-Large devices (larger desktops, 1400px and up)
@media (max-width: 2400px) {

    .nube_black_1 {
        opacity: .5;
        position: absolute;
        top: 10%;
    }

    .nube_black_2 {
        opacity: .3;
        position: absolute;
        top: 15%;
        right: 5%;
        width: 80px;
    }

    .nube_black_3 {
        opacity: .7;
        position: absolute;
        bottom: 12%;
        left: 30%;
        width: 80px;
    }

    .nube_black_4 {
        opacity: .7;
        position: absolute;
        bottom: 15%;
        right: 30%;
        width: 40px;
    }

    .nube_black_5 {
        z-index: -3;
        position: absolute;
        top: 0%;
        left: 20%;
        width: 60px;
    }

    .nube_black_6 {
        z-index: -5;
        position: absolute;
        top: 0%;
        left: 50%;
        width: 50px;
    }


    .nube_delivery {
        opacity: .2;
        position: absolute;
        top: 0%;
        right: 0%;
        z-index: -2;
        width: 400px;
    }

    .nubefa_girl_2 {
        position: absolute;
        bottom: 0%;
        right: -15%;
    }

    .nubefa_girl_3 {
        z-index: -2;
        width: 500px;
        // position: absolute;
        bottom: 0%;
        right: 50%;
    }

    .nubefa-login-personas {
        right: 0%
    }

}

@import "./correcciones.scss";