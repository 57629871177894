

.is-size-patch-1{

    font-size: 4rem !important;
} 
.invisible{
    display:none
}
.border{
    border:1px solid black !important;
}

.pricing-plan{
    flex-grow: 0.2 !important;
    // justify-content:space-around !important;
}
.pricing-table{
    justify-content: space-around  !important;
}
.hr-footer{
    margin:0;
    margin-bottom: 1rem !important;
}
.center{
    text-align: center;
}
.figur{
    display:flex;
    align-items: center;
}
.logo{
    width: 180px !important;
    height: auto !important;
}
.nube{
    margin-left: 90px;
}

@media screen and (min-width:768px){
    .estadisticas{
        display:flex;
        flex-direction:column;
        align-items: center;
        height: auto;
        justify-content: center;
    }
    .fab-left{
        display:none
    }
    .item-stat{
        height:0px;
    }
    .imagen-chica{
        margin-left:100px;
    }

}


@media screen and (max-width: 768px){
    .is-size-patch-1{
        
        font-size:1rem !important;
    } 
    .fab-left{
        display:block;
        position:fixed;
        bottom:1%;
        z-index:10000

    }

    .colmd{
        margin:-0.75rem;
    }
    .margin-b{
        margin-bottom:20px !important;
    }
    .nube{
        margin-left:0px;
        margin-top:10px;
    }
    .correccion{
        margin-top:0em !important;
        padding-top:0px !important;
    }
    .invisible{
        display:block
    }
    .is-size-patch-7{

        font-size: 0.65rem !important;
    } 
    .phone{
        display:block;
        position:relative;
        width:60px;
    }
    .button-is-size-patch-1{
        font-size: 0.5rem !important;
    }
    .logo{
        height:auto !important;
        width:120px !important;
    }
}
